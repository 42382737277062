import { Grid } from '@mui/material'
import React from 'react'
import './PumpInfoPanelFields.sass'
import { PumpInfoPanelField } from './PumpInfoPanelField'

interface PumpInfoPanelFieldsProps {
  getFieldValue: (fieldName: string) => string | undefined
  getLabelText: (fieldName: string) => string | undefined
}

enum fieldNames {
  organizationName = 'organizationName',
  organizationId = 'organizationId',
  customerId = 'customerId',
  pumpCountryCode = 'pumpCountryCode',
  firstAlarmOccurrence = 'firstAlarmOccurrence',
  lastAlarmOccurrence = 'lastAlarmOccurrence',
  pumpLocation = 'pumpLocation',
  pumpTechnicalLocation = 'pumpTechnicalLocation',
  pumpName = 'pumpName',
  pumpSerialNumber = 'pumpSerialNumber',
  pumpSensorsId0 = 'pumpSensorsId0',
  pumpSensorsSerialNumber0 = 'pumpSensorsSerialNumber0',
  pumpType = 'pumpType',
  pumpApplication = 'pumpApplication',
  pumpTypeSeries = 'pumpTypeSeries',
  pumpSize = 'pumpSize',
  pumpManufacturer = 'pumpManufacturer',
  pumpRatedSpeed = 'pumpRatedSpeed',
  pumpVariableSpeed = 'pumpVariableSpeed',
  pumpRatedPower = 'pumpRatedPower',
  pumpNumberOfBlades = 'pumpNumberOfBlades',
  pumpId = 'pumpId',
  pumpRatedSpeedPump = 'pumpRatedSpeedPump',
  pumpRatedSpeedMotor = 'pumpRatedSpeedMotor',
  pumpFlowRate = 'pumpFlowRate',
  pumpRatedHead = 'pumpRatedHead',
  pumpNumberOfStages = 'pumpNumberOfStages',
  pumpNumberOfEntries = 'pumpNumberOfEntries'
}

const fullGridWidth = 12
const halfGridWidth = fullGridWidth / 2
const thirdGridWidth = fullGridWidth / 3

export const PumpInfoPanelFields: React.FC<PumpInfoPanelFieldsProps> = ({
  getFieldValue,
  getLabelText
}) => {
  const spaceHeight = '16px'

  return (
    <Grid container spacing={1}>
      <PumpInfoPanelField
        name={fieldNames.organizationName}
        value={getFieldValue(fieldNames.organizationName)}
        label={getLabelText(fieldNames.organizationName)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpCountryCode}
        value={getFieldValue(fieldNames.pumpCountryCode)}
        label={getLabelText(fieldNames.pumpCountryCode)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.organizationId}
        value={getFieldValue(fieldNames.organizationId)}
        label={getLabelText(fieldNames.organizationId)}
        width={fullGridWidth}
        visible={true}
      />
      <Grid item xs={fullGridWidth} style={{ height: spaceHeight }} />
      <PumpInfoPanelField
        name={fieldNames.firstAlarmOccurrence}
        value={getFieldValue(fieldNames.firstAlarmOccurrence)}
        label={getLabelText(fieldNames.firstAlarmOccurrence)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.lastAlarmOccurrence}
        value={getFieldValue(fieldNames.lastAlarmOccurrence)}
        label={getLabelText(fieldNames.lastAlarmOccurrence)}
        width={halfGridWidth}
        visible={true}
      />
      <Grid item xs={fullGridWidth} style={{ height: spaceHeight }} />
      <PumpInfoPanelField
        name={fieldNames.pumpLocation}
        value={getFieldValue(fieldNames.pumpLocation)}
        label={getLabelText(fieldNames.pumpLocation)}
        width={fullGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpTechnicalLocation}
        value={getFieldValue(fieldNames.pumpTechnicalLocation)}
        label={getLabelText(fieldNames.pumpTechnicalLocation)}
        width={fullGridWidth}
        visible={true}
      />
      <Grid item xs={fullGridWidth} style={{ height: spaceHeight }} />
      <PumpInfoPanelField
        name={fieldNames.pumpName}
        value={getFieldValue(fieldNames.pumpName)}
        label={getLabelText(fieldNames.pumpName)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpSerialNumber}
        value={getFieldValue(fieldNames.pumpSerialNumber)}
        label={getLabelText(fieldNames.pumpSerialNumber)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpSensorsId0}
        value={getFieldValue(fieldNames.pumpSensorsId0)}
        label={getLabelText(fieldNames.pumpSensorsId0)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpSensorsSerialNumber0}
        value={getFieldValue(fieldNames.pumpSensorsSerialNumber0)}
        label={getLabelText(fieldNames.pumpSensorsSerialNumber0)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpType}
        value={getFieldValue(fieldNames.pumpType)}
        label={getLabelText(fieldNames.pumpType)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpApplication}
        value={getFieldValue(fieldNames.pumpApplication)}
        label={getLabelText(fieldNames.pumpApplication)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpTypeSeries}
        value={getFieldValue(fieldNames.pumpTypeSeries)}
        label={getLabelText(fieldNames.pumpTypeSeries)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpSize}
        value={getFieldValue(fieldNames.pumpSize)}
        label={getLabelText(fieldNames.pumpSize)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpRatedSpeedPump}
        value={getFieldValue(fieldNames.pumpRatedSpeedPump)}
        label={getLabelText(fieldNames.pumpRatedSpeedPump)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpVariableSpeed}
        value={getFieldValue(fieldNames.pumpVariableSpeed)}
        label={getLabelText(fieldNames.pumpVariableSpeed)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpRatedSpeedMotor}
        value={getFieldValue(fieldNames.pumpRatedSpeedMotor)}
        label={getLabelText(fieldNames.pumpRatedSpeedMotor)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpRatedPower}
        value={getFieldValue(fieldNames.pumpRatedPower)}
        label={getLabelText(fieldNames.pumpRatedPower)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpFlowRate}
        value={getFieldValue(fieldNames.pumpFlowRate)}
        label={getLabelText(fieldNames.pumpFlowRate)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpRatedHead}
        value={getFieldValue(fieldNames.pumpRatedHead)}
        label={getLabelText(fieldNames.pumpRatedHead)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpNumberOfBlades}
        value={getFieldValue(fieldNames.pumpNumberOfBlades)}
        label={getLabelText(fieldNames.pumpNumberOfBlades)}
        width={thirdGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpNumberOfStages}
        value={getFieldValue(fieldNames.pumpNumberOfStages)}
        label={getLabelText(fieldNames.pumpNumberOfStages)}
        width={thirdGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpNumberOfEntries}
        value={getFieldValue(fieldNames.pumpNumberOfEntries)}
        label={getLabelText(fieldNames.pumpNumberOfEntries)}
        width={thirdGridWidth}
        visible={true}
      />
      <Grid item xs={fullGridWidth} style={{ height: spaceHeight }} />
      <PumpInfoPanelField
        name={fieldNames.pumpManufacturer}
        value={getFieldValue(fieldNames.pumpManufacturer)}
        label={getLabelText(fieldNames.pumpManufacturer)}
        width={halfGridWidth}
        visible={true}
      />
      <PumpInfoPanelField
        name={fieldNames.pumpRatedSpeed}
        value={getFieldValue(fieldNames.pumpRatedSpeed)}
        label={getLabelText(fieldNames.pumpRatedSpeed)}
        width={halfGridWidth}
        visible={true}
      />
      <Grid item xs={fullGridWidth} style={{ height: spaceHeight }} />
      <PumpInfoPanelField
        name={fieldNames.pumpId}
        value={getFieldValue(fieldNames.pumpId)}
        label={getLabelText(fieldNames.pumpId)}
        width={fullGridWidth}
        visible={true}
      />
      <Grid item xs={fullGridWidth} style={{ height: spaceHeight }} />
    </Grid>
  )
}
